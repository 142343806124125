<template>
  <footer>
    <p>
      <a href="https://twitter.com/trinito_real" target="_blink">Trinito Twitter</a>
    </p>
    <p>
      For inquiries, please contact us at 
      <a href="mailto:trinito@trinito.co">trinito@trinito.co</a>
    </p>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: auto;
  width: 100%;
  height: 100px;
  background-color:#1A1A1A;
  backdrop-filter: blur(5px);
  color: #fff;
  font-size: 14px;
  font-weight: 200;
  padding:  0 26px 18px 26px;

  p {
    text-align: right;
    line-height: 1.4;

    & + p {
      margin-top: 8px;
    }

    a {
      text-decoration: underline;
    }
  }
}
</style>