import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home";
import Members from "@/views/Members";
// import Trading from "@/views/Trading";
// import Investment from "@/views/Investment";
import Portfolio from "@/views/Portfolio";
import Thoughts from '@/views/Thoughts';
import Mentions from '@/views/Mentions';
import Media from '@/views/Media';

import KYB from '@/views/KYB';
import KYC from '@/views/KYC';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/members",
    name: "Members",
    component: Members
  },
  // {
  //   path: "/trading",
  //   name: "Trading",
  //   component: Trading
  // },
  // {
  //   path: "/investment",
  //   name: "Investment",
  //   component: Investment
  // },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio
  },
  {
    path: "/thoughts",
    name: "Thoughts",
    component: Thoughts
  },
  {
    path: "/mentions",
    name: "Mentions",
    component: Mentions
  },
  {
    path: '/media',
    name: 'Media',
    component: Media
  },
  {
    path: '/kyb',
    name: 'KYB',
    component: KYB
  },
  {
    path: '/kyc',
    name: 'KYC',
    component: KYC
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
        behavior: 'smooth'
      };
    }
  }
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		if (err.name !== 'NavigationDuplicated') throw err;
	});
};

export default router;
