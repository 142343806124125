<template>
  <div id="logo" @click="$emit('click')">
    <img class="text" src="../assets/logo.svg" alt="logo">
  </div>
</template>

<script>
export default {
  name: "Logo"
}
</script>

<style lang="scss" scoped>
#logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  font-weight: 700;
  user-select: none;
  cursor: pointer;

  /* &:hover {
    path {
      fill: $primary;
    }
  } */
  
  .text {
    width: 320px;
    height: 82px;
    margin-top: 16px;
  }
}
</style>