<template>
  <main>
    <h2>Our Thoughts</h2>
    <div class="thoughts">
      <a
        v-for="(thought, index) in thoughts"
        :class="{ thought: true, original: thought.original }"
        :key="index"
        :href="thought.link"
        target="_blink"
      >
        <img :src="thought.image" />
        <div class="desc">
          <p class="title" v-html="thought.title"></p>
          <p class="cont">{{ thought.cont }}</p>
        </div>
      </a>
    </div>
  </main>
</template>

<script>
export default {
  name: "Thoughts",
  data() {
    return {
      thoughts: [
        {
          title: "예대마진 ‘눈덩이’…고수익 사업 된 스테이블 코인",
          const: "",
          link: "https://magazine.hankyung.com/money/article/202406134113c",
          image: require("../assets/hankyung_stable.jpg"),
        },
        {
          title: "놀이인가 투기인가…인터넷 세대 사로잡은 밈코인",
          cont: "",
          link: "https://magazine.hankyung.com/money/article/202405148214c",
          image: require("../assets/hankyung_meme.jpg"),
        },
        {
          title: "변수 많아진 비트코인, 4년 주기설 이어질까",
          cont: "",
          link: "https://magazine.hankyung.com/money/article/202404152479c",
          image: require("../assets/hankyung_BTC_cycle.jpg"),
        },
        {
          title: "비트코인 현물 ETF 출시…‘디지털 골드’의 귀환",
          cont: "",
          link: "https://magazine.hankyung.com/money/article/202403271346c#_enliple",
          image: require("../assets/hankyung_BTC_ETF.png"),
        },
        {
          title:
            "[Original] 인듀어런스와 퓨저니스트: 웹3 게이밍 혁신과 저변확대의 새로운 챕터 (한글)",
          cont: "",
          link: "https://medium.com/@trinito/f35a1816d316",
          image: require("../assets/endurance_fusionist.webp"),
        },
        {
          title:
            "[Original] Introduction to Endurance and Fusionist: Innovation and Adoption for Advanced Web3 Gaming",
          cont: "",
          link: "https://medium.com/coinmonks/introduction-to-endurance-and-fusionist-705ada78bbc7",
          image: require("../assets/endurance_fusionist.webp"),
        },
        {
          title: "[Original] Upbit & Bithumb 2Q23 (1H23) Comparison",
          cont: "",
          link: "https://trinito.medium.com/upbit-bithumb-2q23-1h23-comparison-7385e986574a",
          image: require("../assets/UpbitVsBithumb2Q23.jpg"),
        },
        {
          title: "[Original] 비트코인 현물 ETF 출시 전망",
          cont: "",
          link: "https://trinito.medium.com/60287ae93ca",
          image: require("../assets/trinito_thought.jpeg"),
          original: true,
        },
        {
          title: "[Original] Upbit vs. Bithumb 1Q23 Comparison",
          cont: "",
          link: "https://trinito.medium.com/upbit-vs-bithumb-1q23-comparison-ffec081f36ba",
          image: require("../assets/UpbitVsBithumb1Q23.jpg"),
        },
        {
          title: "[Original] Investment Thesis of KTX.Finance",
          cont: "",
          link: "https://medium.com/coinmonks/investment-thesis-of-ktx-finance-e0bba576d110",
          image: require("../assets/KTX.webp"),
        },
        {
          title: "[Original] Upbit vs Bithumb Comparison — FY22",
          cont: "",
          link: "https://medium.com/coinmonks/baf1dc9e9b0c",
          image: require("../assets/upbitVsBithumb.jpg"),
        },
        {
          title: "[Original] At-a-Glance: Upbit 4Q 2022 & FY 2022 Wrap-Up",
          cont: "",
          link: "https://medium.com/coinmonks/86e84343a4b7",
          image: require("../assets/upbit4q22.jpg"),
        },
        {
          title: "[Original] 크립토 vs. SEC: 비트코인 ETF와 리플 증권성 논쟁",
          cont: "",
          link: "https://trinito.medium.com/d2fd7a91ec33",
          image: require("../assets/trinito_thought.jpeg"),
          original: true,
        },
        {
          title: "[Original] 시황 리뷰 — 크립토 고속도로 휴게소",
          cont: "",
          link: "https://trinito.medium.com/813ac4c2b252",
          image: require("../assets/trinito_thought.jpeg"),
          original: true,
        },
        {
          title:
            "[Original] A Deep Dive: the Ethereum Staking Protocols Ahead of Shanghai Fork",
          cont: "",
          link: "https://medium.com/coinmonks/ddb6fb5cc037",
          image: require("../assets/trinito_thought.jpeg"),
          original: true,
        },
        {
          title:
            "[Original] 딥다이브: 상하이 포크 앞둔 이더리움 스테이킹 프로토콜 (한글)",
          cont: "",
          link: "https://trinito.medium.com/3fba94c1704b",
          image: require("../assets/trinito_thought.jpeg"),
          original: true,
        },
        {
          title: "[Original] How to Invest in Bitcoin Mining",
          cont: "",
          link: "https://trinito.medium.com/how-to-invest-in-bitcoin-mining-b29cd4022d15",
          image: require("../assets/mine.webp"),
        },
        {
          title: "[Original] 비트코인 채굴기업에 투자하는 방법 (한글)",
          cont: "신재생 에너지 채굴기업 Pow.re의 사례",
          link: "https://trinito.medium.com/cf9ec55152f6",
          image: require("../assets/powreBit.jpg"),
        },
        {
          title: "[Original] 2023년 크립토 인플레이션: 공짜 점심은 없다 (한글)",
          cont: "",
          link: "https://medium.com/@trinito/2023년-크립토-인플레이션-공짜-점심은-없다-4e176a1ef46b",
          image: require("../assets/trinito_thought.jpeg"),
          original: true,
        },
        {
          title: "[Original] 올해 크립토 산타랠리가 일어날까요? (한글)",
          cont: "",
          link: "https://medium.com/@trinito/올해-크립토-산타랠리가-일어날까요-1d9da5ebbdf4",
          image: require("../assets/santaRally.webp"),
        },
        {
          title:
            "[Original] Are We Going to Get Crypto Santa Claus Rally This Year?",
          cont: "",
          link: "https://medium.com/coinmonks/are-we-going-to-get-crypto-santa-claus-rally-this-year-2ab6d2588cfc",
          image: require("../assets/santaRally.webp"),
        },
        {
          title:
            "[Original] 국내 2대 거래소와 코인베이스의 2022년 3분기 실적 결과 비교 (한글)",
          cont: "",
          link: "https://medium.com/@trinito/국내-2대-거래소와-코인베이스의-2022년-3분기-실적-결과-비교-16cc9bd46d60",
          image: require("../assets/vsCoinBase.png"),
        },
        {
          title:
            "[Original] At-a-Glance: Upbit 3Q 2022 [Income Statement Highlights]",
          cont: "",
          link: "https://medium.com/@trinito/at-a-glance-upbit-3q-2022-3e24a05825b4",
          image: require("../assets/upbit3Q22.png"),
        },
        {
          title:
            "[Original] At-a-glance: Bithumb 3Q22 [Income Statement Highlights]",
          cont: "",
          link: "https://medium.com/@trinito/at-a-glance-bithumb-3q22-4cddae13a4da",
          image: require("../assets/bithumb3Q22.png"),
        },
        {
          title: "[Original] BWB 2022 현장 스케치 (한글)",
          cont: "",
          link: "https://trinito.medium.com/73197e62b7c6",
          image: require("../assets/BWB.jpeg"),
        },
        {
          title:
            "[Original] 크립토 컨퍼런스 현장 스케치: 토큰2049 싱가포르 2022 (한글)",
          cont: "",
          link: "https://trinito.medium.com/710fd03d57df",
          image: require("../assets/token2049.png"),
        },
        {
          title:
            "[Original] At-a-glance: Upbit Q2 2022 [Income Statement Highlights]",
          cont: "",
          link: "https://trinito.medium.com/at-a-glance-upbit-2q22-3ef4af84c2bd",
          image: require("../assets/upbit2Q22.png"),
        },
        {
          title:
            "[Original] At-a-glance: Bithumb Q2 2022 [Income Statement Highlights]",
          cont: "",
          link: "https://trinito.medium.com/at-a-glance-bithumb-2q22-5790db8564d2",
          image: require("../assets/bithumb2Q22.png"),
        },
        {
          title: "한눈에 살펴보기: 코인베이스 2Q22 실적 발표 (한글)",
          cont: "",
          link: "https://trinito.medium.com/%ED%95%9C%EB%88%88%EC%97%90-%EC%82%B4%ED%8E%B4%EB%B3%B4%EA%B8%B0-%EC%BD%94%EC%9D%B8%EB%B2%A0%EC%9D%B4%EC%8A%A4-2q22-%EC%8B%A4%EC%A0%81-%EB%B0%9C%ED%91%9C-9faa038597b9",
          image: require("../assets/coinbase.png"),
        },
        {
          title: "기술 발전에 따른 크립토 시장 사이클 (한글)",
          cont: `“크립토는 죽었나요?”`,
          link: "https://trinito.medium.com/%EA%B8%B0%EC%88%A0-%EB%B0%9C%EC%A0%84%EC%97%90-%EB%94%B0%EB%A5%B8-%ED%81%AC%EB%A6%BD%ED%86%A0-%EC%8B%9C%EC%9E%A5-%EC%82%AC%EC%9D%B4%ED%81%B4-%ED%81%AC%EB%A6%BD%ED%86%A0%EB%8A%94-%EC%A3%BD%EC%97%88%EB%82%98%EC%9A%94-c48ee49cbcac",
          image: require("../assets/chart.png"),
          original: true,
        },
        {
          title: "Tech-Induced Crypto Market Cycles",
          cont: "“Is the Crypto Dead Now?”",
          link: "https://medium.com/coinmonks/tech-induced-crypto-market-cycles-9ee00bbe28b2",
          image: require("../assets/chart.png"),
          original: true,
        },
        {
          title:
            "Hero Blaze: Three Kingdoms(무한돌파삼국지 리버스) 글로벌 런칭 (한글)",
          cont: "한국 P2E 게임의 개선과 발전",
          link: "https://trinito.medium.com/hero-blaze-three-kingdoms-%EA%B8%80%EB%A1%9C%EB%B2%8C-%EB%9F%B0%EC%B9%AD-%ED%95%9C%EA%B5%AD-p2e-%EA%B2%8C%EC%9E%84%EC%9D%98-%EA%B0%9C%EC%84%A0%EA%B3%BC-%EB%B0%9C%EC%A0%84-c5d545426f18",
          image: require("../assets/mudol.png"),
        },
        {
          title: "Hero Blaze: Three Kingdoms — Global Launch",
          cont: "",
          link: "https://medium.com/coinmonks/hero-blaze-three-kingdoms-global-launch-1a82a0b26133",
          image: require("../assets/mudol.png"),
        },
        {
          title:
            "[Links] Centralized Exchanges Series 1Q22 - Upbit, Bithumb, Coinbase",
          cont: "",
          link: "https://twitter.com/trinito_real/status/1539063399522242560?s=20&t=z9654KtEY7TtWBP2-oeyfw",
          image: require("../assets/centralized.png"),
        },
        {
          title: "[Original] Bithumb FS & Comparison vs Upbit - 1Q22",
          cont: "",
          link: "https://medium.com/coinmonks/bithumb-fs-comparison-vs-upbit-1q22-616426d1d3e5",
          image: require("../assets/bithumb.png"),
        },
        {
          title:
            "[Original] Upbit vs Coinbase Comparison - 1Q22<br> [1Q22 Financial Statements Comparisons]",
          cont: "",
          link: "https://trinito.medium.com/upbit-vs-coinbase-comparison-1q22-3a4657f01b8e",
          image: require("../assets/upbit-financial.png"),
        },
        {
          title:
            "[Original] At-a-glance: Upbit Q1 2022 [Income Statement Highlights]",
          cont: "",
          link: "https://medium.com/coinmonks/at-a-glance-upbit-q1-2022-18b1bd8b4717",
          image: require("../assets/upbit-income.png"),
        },
        {
          title:
            "[Original] At-a-glance: Upbit Q1 2022 [Balance Sheet Highlights]",
          cont: "",
          link: "https://trinito.medium.com/at-a-glance-upbit-q1-2022-293140213a0f",
          image: require("../assets/upbit.jpg"),
        },
        {
          title: "[Original] 시황 리뷰 - 크립토 고속도로 휴게소 (한글)",
          cont: "",
          link: "https://trinito.medium.com/%EC%8B%9C%ED%99%A9-%EB%A6%AC%EB%B7%B0-%ED%81%AC%EB%A6%BD%ED%86%A0-%EA%B3%A0%EC%86%8D%EB%8F%84%EB%A1%9C-%ED%9C%B4%EA%B2%8C%EC%86%8C-86390dd22240",
          image: require("../assets/trinito_thought.jpeg"),
          original: true,
        },
        {
          title: "[Summary] 성공적 메타버스 프로젝트가 갖는 공통점은? (한글)",
          cont: "",
          link: "https://twitter.com/trinito_real/status/1514092116095295488",
          image: require("../assets/klaycity_thought.jpeg"),
        },
        {
          title:
            "[Summary] What’s the patterns among successful metaverse projects?",
          cont: "",
          link: "https://twitter.com/trinito_real/status/1513817776745443329",
          image: require("../assets/klaycity_thought.jpeg"),
        },
        {
          title:
            "[Original] Exploring the Metaverse Frontier: Yuga Labs, DeFi Kingdoms, and KlayCity",
          cont: "",
          link: "https://trinito.medium.com/exploring-the-metaverse-frontier-yuga-labs-defi-kingdoms-and-klaycity-6d7563550c5f",
          image: require("../assets/klaycity_summary.jpeg"),
        },
        {
          title: "[Summary] Analysis of League of Kingdoms: Land 3.0",
          cont: "",
          link: "https://twitter.com/trinito_real/status/1501830522598998025?s=20&t=tY41DSjklXV-wTpIQSDW3Q",
          image: require("../assets/lok_summary.png"),
        },
        {
          title: "[Original] Analysis of League of Kingdoms Land 3.0 Update",
          cont: "",
          link: "https://trinito.medium.com/analysis-of-league-of-kingdoms-land-3-0-update-aae1cf439a97",
          image: require("../assets/lok_thought.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  max-width: 600px;
  margin-top: 100px;

  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 8px;
    padding-bottom: 36px;
    border-bottom: solid 1px $grayc;
  }

  .thoughts {
    text-align: center;
    font-weight: 300;
    color: $grayc;
    padding: 88px 24px 92px;
    line-height: 2;
    // border-bottom: solid 1px $grayc;

    .thought {
      display: flex;
      flex-direction: column;
      align-items: center;

      &.original {
        img {
          padding: 48px;
        }
      }

      & + .thought {
        margin-top: 32px;
      }

      img {
        width: 100%;
        min-width: 240px;
        border-radius: 8px;
        border: solid 1px #eee;
        background-color: #fff;
      }

      .desc {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .title {
          line-height: 1.6;
          font-size: 15px;
          color: $graya;
        }

        .cont {
          line-height: 1.4;
          margin-top: 8px;
          font-size: 14px;
          color: $grayc;
        }
      }
    }
  }
}
</style>
