<template>
  <div id="app">
    <section>
      <Logo class="desktop" @click="$router.push('/')" />
      <nav class="desktop">
        <ul>
          <li
            v-for="nav in navList"
            :key="nav.id"
            @click="goLink(nav.id)"
            :class="{ current: $route.name === nav.id }"
          >
            {{ nav.name }}
          </li>
          <li class="link">
            <a href="https://opensea.io/collection/trinito" target="_blank">
              Eye Candy
            </a>
          </li>
        </ul>
      </nav>
      <nav :class="{ mobile: true, active: list }">
        <div class="row">
          <svg xmlns="http://www.w3.org/2000/svg" width="109.88" height="28" viewBox="0 0 160 40.771">
            <path id="logo-text" d="M755.639,225.124v-30.2h12.236v-7.434H735v7.434h12.236v30.2Zm19.964,0V211.272c0-4.109,2.632-6.6,7.018-6.6a9.1,9.1,0,0,1,4.2.923l-.092-7.711a10.069,10.069,0,0,0-3.555-.462c-3.463,0-5.956,1.247-7.572,3.417v-2.77h-8.219v27.057Zm18.533-30.058a5.033,5.033,0,1,0-5.033-5.033A4.876,4.876,0,0,0,794.136,195.066Zm4.109,30.151v-27.15h-8.219v27.15Zm13.684-.092v-13.9c0-4.2,1.939-6.741,5.171-6.741,3.094,0,4.756,2.262,4.756,6.372v14.267h8.219V209.472c0-7.48-4.294-12.051-10.574-12.051-3.463,0-5.956,1.247-7.572,3.417v-2.77h-8.219v27.057ZM839,195.066a5.033,5.033,0,1,0-5.033-5.033A4.876,4.876,0,0,0,839,195.066Zm4.109,30.151v-27.15h-8.219v27.15Zm16.686-.092V205.131h5.356v-7.064H859.8v-8.034H851.58v8.034h-5.356v7.064h5.356v19.993Zm20.841.646c8.357,0,14.36-5.956,14.36-14.175s-6-14.175-14.36-14.175-14.36,5.956-14.36,14.175S872.283,225.771,880.64,225.771Zm0-7.018c-3.879,0-6.418-3-6.418-7.157s2.539-7.157,6.418-7.157,6.418,3,6.418,7.157S884.519,218.752,880.64,218.752Z" transform="translate(-735 -185)" fill="#1a1a1a"/>
          </svg>
          <div class="list">
            <div class="menu-icon">
              <input class="menu-icon__checkbox" type="checkbox" v-model="list">
              <div>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>

        <div class="list">
          <ul>
            <li
              v-for="(nav, index) in navList"
              :key="index"
              :class="{ current: $route.name === nav.id }"
              @click="goLink(nav.id)"
            >
              {{ nav.name }}
            </li>
            <li class="link">
              <a href="https://opensea.io/collection/trinito" target="_blank">
                Eye Candy
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <transition name="fade" mode="out-in"><router-view /></transition>
    </section>

    <Footer />
  </div>
</template>

<script>
import Logo from '@/components/Logo';
import Footer from '@/components/Footer';

export default {
  name: "App",
  components: { Logo, Footer },
  data () {
    return {
      navList: [
        {
          id: 'Home',
          name: 'About'
        },
        {
          id: 'Members',
          name: 'Members'
        },
        // {
        //   id: 'Trading',
        //   name: 'Trading'
        // },
        // {
        //   id: 'Investment',
        //   name: 'Investment'
        // },
        {
          id: 'Portfolio',
          name: 'Portfolio'
        },
        {
          id: 'Thoughts',
          name: 'Our Thoughts'
        },
        {
          id: 'Mentions',
          name: 'Other Mentions'
        },
        // {
        //   id: 'Media',
        //   name: 'Media Exposure'
        // }
      ],
      list: false
    }
  },
  methods: {
    goLink (link) {
      this.list = false;
      this.$router.push({ name: link });
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: 'Be Vietnam Pro', sans-serif, Helvetica, Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1A1A1A;
  min-height: 100%;
}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: #FCFDFF;
}

textarea {
  -webkit-appearance: none;
}

body, h1, h2, h3, h4, h5, h6, input, textarea, select {
  color: var(--primary-black);
}

h1 {
  margin-top: 0;
}

html {
  height: 100%;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

a {
  text-decoration: none;
  color: var(--primary-black);
}

p {
  margin: 0;
}

button {
  padding: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul {
  list-style: none;
  padding-left: 0;
}
</style>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  align-items: center;

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    padding: 80px;

    @media (max-width: $max-mobile) {
      min-height: 100vh;
      padding: 120px 20px 80px;
    }
  }
}

.desktop {
  @media (max-width: $max-mobile) {
    display: none !important;
  }
}

nav.desktop {
  display: flex;
  justify-content: center;

  @media (max-width: $max-mobile) {
    display: none;
  }

  ul {
    display: flex;
    align-items: center;
    margin-top: 72px;

    li {
      position: relative;
      color: $grayc;
      font-size: 16px;
      font-weight: 300;
      transition: all 0.2s;
      user-select: none;
      cursor: pointer;

      &.current {
        color: $graya;
        cursor: default;

        &::after {
          position: absolute;
          bottom: -2px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: $graya;
          transform-origin: left;
          animation: underline 0.5s ease-out;
          content: '';
        }

        &:hover {
          color: $graya;
        }
      }

      &:hover {
        color: $grayb;

        &.link {
          color: $primary;
        }
      }

      & + li {
        margin-left: 28px;
      }
    }
  }
}

nav.mobile {
  display: none;
  background-color: rgba($color: #fff, $alpha: 0.3);
  backdrop-filter: blur(5px);
  max-height: 74px;
  transition: all 0.5s ease-out;
  overflow: hidden;
  z-index: 5;

  &.active {
    max-height: 360px;
  }

  @media (max-width: $max-mobile) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
  }

  .list {
    ul {
      li {
        font-weight: 300;

        &.current {
          text-decoration: underline;
        }

        & + li {
          margin-top: 16px;
        }
      }
    }
  }

  .menu-icon {
    position: relative;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    cursor: pointer;

    .menu-icon__checkbox {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      cursor: pointer;
      z-index: 2;
      -webkit-touch-callout: none;
      position: absolute;
      opacity: 0;

      &:checked + div {
        span {
          &:first-of-type {
            transform: rotate(45deg);
            top: 5px;
          }
          &:last-of-type {
            transform: rotate(-45deg);
            bottom: 5px;
          }
        }
      }
    }

    div {
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 22px;
      height: 12px;
    }

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      background-color: #1A1A1A;
      border-radius: 1px;
      transition: all 0.3s ease-out;

      &:first-of-type {
        top: 0;
      }
      &:last-of-type {
        bottom: 0;
      }
    }
  }
}


.fade-enter-active {
  animation: fade 0.4s ease-out;
}
.fade-leave-active {
  animation: fade 0.4s ease-out reverse;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes underline {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}
</style>
