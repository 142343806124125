<template>
  <main>
    <h2>Media Exposure</h2>
    <p>
      Coming Soon...
    </p>
  </main>
</template>

<script>
export default {
  name: "Media",
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  max-width: 600px;
  margin-top: 100px;

  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 8px;
    padding-bottom: 36px;
    border-bottom: solid 1px $grayc;
  }

  p {
    text-align: center;
    font-weight: 300;
    color: $grayc;
    padding: 88px 4px 92px;
    line-height: 2;
    // border-bottom: solid 1px $grayc;
  }
}
</style>