<template>
  <main></main>
</template>

<script>
export default {
  name: "KYB",
  created() {
    window.location.href = "https://kyc.argos-solutions.io/apply/231661614525049";
  }
}
</script>

<style>

</style>