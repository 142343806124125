<template>
  <main>
    <h2>Introduction</h2>
    <section>
      <p>
        Trinito is a trading investment company focusing on digital assets. 
        Our trading strategy seeks opportunities with a risk-adjusted approach driven by market inefficiencies.
      </p>
      
      <p>
        Our mid to long term investment will focus on supporting cryptocurrencies, 
        protocols and new assets which aim to change our lives using blockchain technology.
      </p>
    </section>

    <h2>Trading</h2>
    <section>
      <ul>
        <li>Our proprietary in-house strategies have delivered premium returns comparable to that of top-tier trading companies</li>
        <li>We are responsible for a significant share of trading volume across various major exchanges for several assets</li>
        <li>Our key trader led the largest OTC volumes and operated largest custody AUC domestically. Other core members consist of developers from well-established blockchain projects within DeFi and P2E segments</li>
      </ul>
    </section>

    <h2>Investment</h2>
    <section>
      <ul>
        <li>Investment Core team members have full-time experience in traditional finance and established firms (Bulge bracket IB / Asset Management / Fintech) as well as key roles in blockchain companies (Central exchange listing & evaluation / Blockchain Venture firm / DeFi protocol and DEX development firm executives)</li>
        <li>We have an extensive network encompassing traditional finance, start-up entrepreneurs and largest crypto firms & projects. As a result, we can provide large post deal operational advantages and critical value-add beyond capital.</li>
        <li>Our multi-stage (starting from seed) investment aims to help blockchain companies that are trying to change our future</li>
      </ul>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
};
</script>

<style lang="scss" scoped>
main {
  max-width: 600px;
  margin-top: 100px;
  padding-bottom: 64px;

  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 8px;
    padding-bottom: 36px;
    border-bottom: solid 1px $grayc;
  }

  section {
    text-align: left;
    font-weight: 300;
    color: $grayc;
    padding: 48px 4px 52px;
    line-height: 2;
    border-bottom: solid 1px $grayc;
    
    p + p{
      margin-top: 8px;
    }

    & + h2 {
      margin-top: 80px;
    }

    ul {
      text-align: left;
      font-weight: 300;
      color: $grayc;
      padding: 0 4px;
      line-height: 2;
      padding-left: 20px;

      li {
        list-style: disc;

        & + li {
          margin-top: 12px;
        }
      }
    }
  }
}
</style>