<template>
  <main></main>
</template>

<script>
export default {
  name: "KYC",
  created() {
    window.location.href = "https://form.argoskyc.com/?pid=ljwsqc5f36";
  }
}
</script>

<style>

</style>