<template>
  <main>
    <h2>Other Mentions</h2>

    <div class="mentions">
      <a
        class="mention"
        v-for="(mention, index) in mentions"
        :key="index"
        :href="mention.link"
        target="_blink"
      >
        <div class="layout"><img :src="mention.image" /></div>
        <div class="desc">
          <p class="title">{{ mention.title }}</p>
          <p class="cont">{{ mention.cont }}</p>
        </div>
      </a>
    </div>
  </main>
</template>

<script>
export default {
  name: "Mentions",
  data() {
    return {
      mentions: [
        {
          title: "Investment Announcement: ARPA Network x Trinito Corporation",
          cont: "",
          link: "https://finance.yahoo.com/news/arpa-network-secures-6m-strategic-131400120.html?guccounter=2",
          image: require("../assets/arpa_trinito.png"),
        },
        {
          title: "비트코인 현물 ETF 출시시 장단점은",
          cont: "",
          link: "https://bloomingbit.io/news/7102479984870031424",
          image: require("../assets/BTCETFmention.png"),
        },
        {
          title: "Investment Announcement: KTX.Finance x Trinito Corporation",
          cont: "",
          link: "https://ktxfinance.medium.com/ktx-finance-raises-4m-to-democratize-perpetuals-trading-df393e762b6c",
          image: require("../assets/ktx.jpg"),
        },
        {
          title:
            "[Coindesk] Crypto Miner Pow.re Raises $9.2M Series A at $150M Valuation",
          cont: "",
          link: "https://www.coindesk.com/business/2023/02/02/crypto-miner-powre-raises-92m-series-a-at-150m-valuation/",
          image: require("../assets/coindesk_powre.jpg"),
        },
        {
          title: "비트코인 반등기?... 채굴업체 주목하는 투자사들",
          cont: "",
          link: "https://n.news.naver.com/article/018/0005410755?sid=101",
          image: require("../assets/bit_mine.jpeg"),
        },
        {
          title: "클레이·위믹스, 인플레이션 비율 비트코인·이더리움의 38배",
          cont: "",
          link: "https://www.digitaltoday.co.kr/news/articleView.html?idxno=467689",
          image: require("../assets/klay_wemix.webp"),
        },
        {
          title: "Investment Announcement: Fnatic x Trinito Corporation",
          cont: "",
          link: "https://fnatic.com/news/fnatic-announces-partnership-with-hivemind-capital",
          image: require("../assets/fnatic_trinito.jpeg"),
        },
        {
          title: "Partnership Announcement: GemHUB x Trinito Corporation",
          cont: "",
          link: "https://medium.com/gemhub/gemhub-x-trinito-partnership-a0c45a02662c",
          image: require("../assets/gemhub.jpeg"),
        },
        {
          title: "Investment Announcement: Epic League x Trinito Corporation",
          cont: "",
          link: "https://medium.com/epicleagueteam/ann-epic-league-completes-strategic-private-rounds-8d72cdd48a83",
          image: require("../assets/epic_league_strategic.jpg"),
        },
        {
          title: '빗썸 최대주주 비덴트 "FTX와 접촉 사실…공동 경영도 검토"',
          cont: "",
          link: "https://www.digitaltoday.co.kr/news/articleView.html?idxno=455041",
          image: require("../assets/bithumb_ftx.jpg"),
        },
        {
          title: "이더리움 생태계 분기점...머지 업그레이드 후 변화는?",
          cont: "",
          link: "https://www.digitaltoday.co.kr/news/articleView.html?idxno=461470",
          image: require("../assets/eth_merge.jpg"),
        },
        {
          title: "업비트·빗썸, 2분기 수익 격차 더욱 확대",
          cont: "",
          link: "https://www.digitaltoday.co.kr/news/articleView.html?idxno=459949",
          image: require("../assets/upbit_bithumb.jpg"),
        },
        {
          title:
            "Advisory Announcement: Hero Blaze Three Kingdoms x Trinito Corporation",
          cont: "",
          link: "https://twitter.com/HeroBlaze3kd/status/1521030533265772545?s=20&t=z5lhicM1qJ6Hp1FKiv2UUA",
          image: require("../assets/mudol_twit.png"),
        },
        {
          title: "ibKUSDT Vault is BACK ON TRACK",
          cont: "",
          link: "https://medium.com/@KLEVA_Protocol_official/ibkusdt-vault-is-back-on-track-af2a0bde52bc",
          image: require("../assets/KLEVA_ibKUSDT.png"),
        },
        {
          title:
            "Partnership Announcement: League of Kingdoms x Trinito Corporation",
          cont: "",
          link: "https://medium.com/league-of-kingdoms-eng/partnership-announcement-league-of-kingdoms-x-trinito-corporation-87fbf9ddbecd",
          image: require("../assets/lok_trinito.png"),
        },
        {
          title: "Strategic Investment: KlayCity x Trinito Corporation",
          cont: "",
          link: "https://sns-sns.gitbook.io/klaycity/the-basics/partnerships",
          image: require("../assets/klaycity_trinito.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  max-width: 600px;
  margin-top: 100px;

  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 8px;
    padding-bottom: 36px;
    border-bottom: solid 1px $grayc;
  }

  .mentions {
    text-align: center;
    font-weight: 300;
    color: $grayc;
    padding: 88px 24px 92px;
    line-height: 2;
    // border-bottom: solid 1px $grayc;

    .mention {
      display: flex;
      flex-direction: column;
      align-items: center;

      & + .mention {
        margin-top: 32px;
      }

      img {
        width: 100%;
        min-width: 240px;
        border-radius: 8px;
      }

      .desc {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .title {
          line-height: 1.4;
          font-size: 14px;
          color: $graya;
        }

        .cont {
          line-height: 1.4;
          margin-top: 8px;
          font-size: 13px;
        }
      }
    }
  }
}
</style>
