<template>
  <main>
    <h2>Portfolio</h2>

    <div class="ports">
      <div class="items">
        <a href="https://redbrick.land/" target="_blank">
          <img src="../assets/redbrick.png" alt="redbrick" />
        </a>

        <a href="https://www.arpanetwork.io/en-US" target="_blank">
          <img src="../assets/arpa.png" alt="arpa" />
        </a>

        <a href="https://selfchain.xyz/" target="_blank">
          <img src="../assets/self.png" alt="selfchain" />
        </a>

        <a href="https://hydroprotocol.finance/" target="_blank">
          <img src="../assets/Hydro BK_logo.svg" alt="hydro" />
        </a>

        <a href="https://www.fusionist.io/" target="_blank">
          <img src="../assets/Fusionist_Logo_04.png" alt="fusionist" />
        </a>

        <a href="https://www.frontier.xyz/" target="_blank">
          <img src="../assets/frontier_logo.png" alt="frontier" />
        </a>

        <a href="https://ktx.finance/" target="_blank">
          <img src="../assets/ktxLogo.png" alt="ktx" />
        </a>

        <a href="https://pow.re/" target="_blank">
          <img src="../assets/powreLogo.png" alt="pow.re" />
        </a>

        <a href="https://fnatic.com/" target="_blank">
          <img src="../assets/fnatic.png" alt="fnatic" />
        </a>

        <a href="https://www.epicleague.io/" target="_blank">
          <img src="../assets/epicleague.png" alt="epicleague" />
        </a>

        <a href="https://heroblaze3kd.io/" target="_blank">
          <img src="../assets/mudol.png" alt="mudol" />
        </a>

        <a href="https://klaycity.com/" target="_blank">
          <img src="../assets/klaycity.png" alt="klaycity" />
        </a>

        <a href="https://www.leagueofkingdoms.com/" target="_blank">
          <img src="../assets/lok.png" alt="lok" />
        </a>
      </div>

      <h4>More to come</h4>
    </div>
  </main>
</template>

<script>
export default {
  name: "Portfolio",
};
</script>

<style lang="scss" scoped>
main {
  width: 100%;
  max-width: 600px;
  margin-top: 100px;

  h2 {
    font-size: 28px;
    margin: 0;
    font-weight: 600;
    letter-spacing: 8px;
    padding-bottom: 36px;
    border-bottom: solid 1px $grayc;
  }

  .ports {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
    color: $grayc;
    padding: 88px 4px 92px;
    line-height: 2;
    /* border-bottom: solid 1px $grayc; */

    img {
      width: 260px;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -36px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      /* padding: 8px; */
      min-height: 240px;
      margin-left: 36px;
    }
  }

  h4 {
    margin-top: 120px;
  }
}
</style>
