<template>
  <div class="profile">
    <!-- <div class="img-container">
      <img :src="member.src" alt="profile">
    </div> -->
    <div class="row">{{ member.name }} - {{ member.title }}</div>
    <p>{{ member.intro }}</p>
  </div>
</template>

<script>
export default {
  name: "Profile",
  props: {
    member: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin-top: 48px;
  font-weight: 300;
  padding: 8px;

  @media (max-width: $max-mobile) {
    width: 80%;
  }

  .add {
    &[type="button"] {
      cursor: pointer;
    }
  }
}

.img-container {
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  img {
    position: absolute;
    width: 90%;
    height: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // background-color: rgb(91, 107, 253);
  }
}

.row {
  margin-top: 16px;
  font-weight: 400;
  font-size: 15px;
}

p {
  margin-top: 14px;
  text-align: left;
  font-size: 13px;
  line-height: 2;
  color: $grayb;
  word-break: keep-all;
}
</style>